import { useEffect } from 'react';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pageUrls } from 'constants/pagesUrls';

import { ALink } from 'components/UIKit/ALink';
import { Button } from 'components/UIKit/Button';
import { BookLoader } from 'components/UIKit/Loaders/BookLoader';
import { BodyM, T } from 'components/basic/Typography';
import { Modal, ModalFooter } from 'components/complex/Modal';
import { StaticInfo } from 'components/complex/pages/home/StaticInfo';

import { TAppState } from 'sp-redux';
import { clearStaticPage } from 'sp-redux/slices/ui';
import { getStaticPageThunk } from 'sp-redux/thunks/ui/getStaticPageThunk';

import { displayErrorFromBackendIfExists } from 'utils/displayErrorFromBackendIfExists';
import { doFetch } from 'utils/doFetch';

import s from './DialogOffer.module.scss';

interface IDialogOffer {
  isOpen: boolean;
  onClose: () => void;
}

export const DialogOffer: FC<IDialogOffer> = ({ isOpen, onClose }) => {
  const {
    ui: { staticPage },
  }: TAppState = useSelector((appState: TAppState) => appState);
  const dispatch = useDispatch();

  const loadPageRequest = async (): Promise<void> => {
    const response = await dispatch(getStaticPageThunk('offer'));
    displayErrorFromBackendIfExists(response);
  };

  const acceptOfferRequest = async (): Promise<void> => {
    const apiPointName = 'acceptAnOffer';
    const response = await doFetch<typeof apiPointName>({
      apiPointName,
    });
    displayErrorFromBackendIfExists(response);
  };

  const acceptAnOfferHandler = async (): Promise<void> => {
    await acceptOfferRequest();
    onClose();
  };

  useEffect(() => {
    loadPageRequest();

    return (): void => {
      dispatch(clearStaticPage());
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title="Изменения правил публичной оферты"
      className={s.modalCustomStyle}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div className={s.wrapperOffer}>
        {staticPage ? (
          <StaticInfo
            className={s.offerPageBackground}
            staticTitle={staticPage.name}
            staticText={staticPage.text}
          />
        ) : (
          <BookLoader />
        )}
      </div>
      <BodyM className={s.goToFormText}>
        <T>Если у вас не оформлен статус самозанятого или ИП,</T>{' '}
        <ALink href={pageUrls.statistics.naturalPerson}>
          <T>заполните форму</T>{' '}
        </ALink>{' '}
        .
      </BodyM>
      <ModalFooter className={s.footerModal}>
        <Button onClick={acceptAnOfferHandler} size="medium">
          Принять
        </Button>
      </ModalFooter>
    </Modal>
  );
};
