/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as Sentry from '@sentry/nextjs';

import { showError } from 'components/UIKit/Toaster/helpers/toast';

//Исользуем any так как по факту бэкенд может присылать не то, что описано в IAPIResponse
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const displayErrorFromBackendIfExists = (response: any): void => {
  // Проверяем первый формат ошибки (стандартный)
  if (response.payload?.errors?.length) {
    const error = response.payload.errors[0];
    if (error.messages?.length) {
      const message = error.messages[0];

      Sentry.captureException(
        'Frontend received a custom error from the backend',
        {
          extra: { error },
        },
      );

      showError(message, {
        position: 'top-center',
      });
      return;
    }
  }

  // Проверяем второй формат ошибки (приходит если фронт генерит неверный URL эндпоинта) или бэкенд возвращает 404
  if (response.error?.message) {
    Sentry.captureException(
      'Frontend recieved 404 or other error from the backend after generating an incorrect URL',
      {
        extra: { error: response.error },
      },
    );

    // eslint-disable-next-line no-console
    console.trace(response.error);

    showError('Ошибка 404. Обратитесь в техподдержку.', {
      position: 'top-center',
    });
  }
};
